const firebaseConfig = {
	fapiKey: 'AIzaSyB4UwgXrurDMUbwGZuQTIgglU4pejcOoNk',
	fauthDomain: 'thevergeghi-shop01.firebaseapp.com',
	fprojectId: 'thevergeghi-shop01',
	fstorageBucket: 'thevergeghi-shop01.appspot.com',
	fmessagingSenderId: '7686472466',
	fappId: '1:7686472466:web:bc294ec76e2fa6e9e529cb',
	fmeasurementId: 'G-CRPMG7252K',
}

export default firebaseConfig
